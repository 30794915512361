<template>
  <default-layout :searchFunction="loadData" searchParamString="q">
    <v-container id="user-management" fluid tag="section">
      <page-loading :show="isLoading" />
      <v-alert type="error" :value="showAlert" dismissible>
        <div v-html="messageAlert" />
      </v-alert>
      <list :items="users" :meta="meta" :permissions="permissions" @changePage="changePage"></list>
    </v-container>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
const PageLoading = () => import('@/components/content-loading/page-loading.vue');
const List = () => import('@/components/user-management/admin-user/list');
import { mapState } from 'vuex';

export default {
  components: {
    DefaultLayout,
    PageLoading,
    List,
  },
  data() {
    return {
      isLoading: false,
      showAlert: false,
      messageAlert: null,
    };
  },
  computed: {
    ...mapState({
      permissions: (state) => state.adminUser.permissions,
      users: (state) => state.adminUser.users,
      meta: (state) => state.adminUser.meta,
      page: (state) => state.adminUser.page,
    }),
  },
  async mounted() {
    try {
      this.isLoading = true;
      let query = this.$route.query;
      this.$store.commit('adminUser/RESET_DEFAULT');
      const response = await this.$store.dispatch('adminUser/getInitData', query);
      console.log('CHECK ADMIN USER INIT DATA: ', response);
    } catch (e) {
      this.showAlert = true;
      this.messageAlert = e;
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    async loadData(query) {
      try {
        this.isLoading = true;
        const response = await this.$store.dispatch('adminUser/getData', query);
        console.log('GET ADMIN USER DATA: ', response);
      } catch (e) {
        this.showAlert = true;
        this.messageAlert = e;
      } finally {
        this.isLoading = false;
      }
    },
    // eslint-disable-next-line no-unused-vars
    async changePage(page) {
      let query = this.$route.query;
      query.page = page;
      await this.loadData(query);
    },
  },
};
</script>
